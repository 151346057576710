<template>
  <div class="container">
    <div class="line-container">
      <div class="line"></div>
      <span></span>
    </div>
    <div class="content-wrapper animate-slide-up">
      <h2 class="title">AI 模型服务</h2>
    </div>
    <div class="ai-model-services animate-slide-up">
      <div class="content">
        <div class="text-content">
          <h1>您提供数据，我们训练模型</h1>
          <p>无论您是希望优化 ERP 系统、提升 MES 功能还是改进 APS 解决方案,</p>
          <p>
            我们的 AI 科学家和工程师团队将与您的数据合作，利用强大的 AI
            技术打造先进的解决方案
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('start-animation');
          // observer.unobserve(entry.target);
        } else {
          entry.target.classList.remove('start-animation');
        }
      });
    },
    {
      threshold: 0.1,
    },
  );

  document.querySelectorAll('.animate-slide-up').forEach(el => {
    observer.observe(el);
  });
});
</script>

<style scoped>
.container {
  position: relative;
  margin: 0 100px;
  height: 100%;
  opacity: 1;
  padding: 150px 0;
}

.line-container {
  position: absolute;
  left: 100px;
  height: 50%;
}

.content-wrapper {
  margin-left: 150px;
  transform: translateY(50px);
  transition: opacity 2s ease-out, transform 2s ease-out;
}

.title {
  margin-bottom: 32px;
  color: white;
  font-size: 40px;
  text-transform: uppercase;
}

.ai-model-services {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  margin-left: 150px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 3s ease-out, transform 3s ease-out;
}
.animate-slide-up {
  opacity: 0;
}
.start-animation {
  opacity: 1;
  transform: translateY(0);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-content {
  flex: 1;
  padding-right: 40px;
}

.text-content h1 {
  font-size: 40px;
  color: white;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 20px;
  color: white;
  line-height: 1.5;
  margin-bottom: 20px;
}

.line {
  inline-size: 2px;
  background: linear-gradient(
    rgb(0, 0, 0),
    rgb(13, 140, 133) 50%,
    rgb(70, 40, 150)
  );
  position: relative;
  transform: translateY(-15%);
  block-size: 100%;
}

span {
  display: block;
  block-size: 14px;
  inline-size: 14px;
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(121, 62, 248);
  border-radius: 50%;
  position: absolute;
  inset-inline-start: 50%;
  inset-block-end: 0;
  transform: translate(-50%, -150%);
}

span::after {
  content: '';
  block-size: 6px;
  inline-size: 6px;
  background-color: rgb(121, 62, 248);
  border-radius: 50%;
  position: absolute;
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}
</style>
