<template>
  <div class="footer">
    <div class="footer-con">
      <div class="footer-top">
        <h4>用AI开启新时代</h4>
        <h4>Leading the New Era with AI</h4>
      </div>
      <div class="footer-mid">
        <div class="mid-left">
          <dl>
            <dt>智能制造优势服务</dt>
            <dd>
              <a href="/solution/solidworks">设计智能化</a>
              <a href="/solution/">生产智能化</a>
              <a href="/vendor/547">管理智能化</a>
              <a href="/tecserv/bigdata">决策智能化</a>
            </dd>
          </dl>
          <dl>
            <dt>软件定制优势服务</dt>
            <dd>
              <a href="/tecserv/custom">软件系统定制</a>
              <a href="/component">全球开发工具销售</a>
              <a href="/tecserv/custom">专业技术服务</a>
              <a href="javascript:callService(0)">企业数字化咨询</a>
            </dd>
          </dl>
          <span></span>
          <dl>
            <dt>关于我们</dt>
            <dd>
              <a href="/about/intro">公司简介</a>
              <a href="/news/8">公司动态</a>
              <a href="/about/contact">业务区域</a>
              <a href="/anli">典型案例</a>
            </dd>
          </dl>
          <dl>
            <dt>区域服务电话</dt>
            <dd>
              <span>重庆 / 023-68661681</span>
              <span>华东 / 18100878086</span>
              <span>华南 / 18166486035</span>
              <span>华北 / 17382392642</span>
            </dd>
          </dl>
          <dl>
            <dt>客户支持</dt>
            <dd class="bnc">
              <div class="bnc-con">
                <a href="/qiye">技术培训</a>
                <i>/</i>
                <a href="/consulting">咨询服务</a>
              </div>
              <a href="tel:400-700-1020" rel="nofollow"
                >服务热线：13636694035</a
              >
              <a href="mailto:sales@evget.com" rel="nofollow"
                >邮箱：great@cybertron.group</a
              >
              <div class="bnc-btn">
                <a
                  href="javascript:window.open('http://wpa.b.qq.com/cgi/wpa.php?ln=1&amp;uin=800018081&amp;site=qq&amp;menu=yes', '_blank', 'height=544, width=644,toolbar=no,scrollbars=no,menubar=no,status=no');"
                  class="qqchart"
                  ><img src="/static/images/qywxicon.png" alt="" />
                  QQ交谈
                </a>
                <a href="javascript:callService(0);" class="zx">
                  <img src="/static/images/zx2.png" alt="" />
                  在线咨询
                </a>
              </div>
            </dd>
          </dl>
        </div>
        <!-- <div class="mid-right">
                <div>
                    <img src="/static/images/code_wx.jpg" alt="">
                </div>
                <p>官方微信</p>
                <div>
                    <img style="width:64px;" src="https://image.evget.com/Content/images/202002/28/a875873e0158bee165ee0a8a01f7b9c4.png" alt="">
                </div>
                <p>官方微博</p>
            </div> -->
      </div>
    </div>
    <div class="phonefooter">
      <p>服务电话</p>
      <div class="Servicephone">
        <div>
          <p>重庆/ 023-68661681</p>
          <p>华东/ 18100878086</p>
        </div>
        <div>
          <p>华南/ 18100878085</p>
          <p>华北/ 17382392642</p>
        </div>
      </div>
      <p>客户支持</p>
      <div class="Customesupport">
        <p><span>技术支持</span><span>咨询服务</span></p>
        <p>服务热线：13636694035</p>
        <p>邮箱：great@cybertron.group</p>
      </div>
      <p>关注我们</p>
      <div class="attention">
        <p>地址 : 重庆市九龙坡区火炬大道69号6幢</p>
        <div>
          <img src="https://www.evget.com/static/images/code_wx.jpg" alt="" />
          <img
            src="https://image.evget.com/Content/images/202002/28/a875873e0158bee165ee0a8a01f7b9c4.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="f-bottom">
      <p style="font-size: 14px;">
        Copyright © 2024 隐数智能科技（上海）有限公司 版权所有 |
        <a href="http://beian.miit.gov.cn/" style="color: #888" target="_blank"
          >沪ICP备2024092725号-1</a
        >
        <img src="http://fgj.sh.gov.cn/assets1/img/bn.png" alt="沪ICP备2024092725号-1" style="margin: 0 6px;">
        <img src="/static/images/jin_11.png" alt="" />沪公网安备
        30172465888659708
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  box-sizing: border-box;
  padding: 50px 0 0 0;
  width: 100%;
  background-color: #2e3033;
  font-size: 18px;
  .footer-con {
    margin: 0 auto;
    width: 1220px;
    .footer-top {
      padding-bottom: 20px;
      font-size: 1.2rem;
      h4 {
        color: #fff;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 2px;
      }
    }
    .footer-mid {
      overflow: hidden;
      padding: 20px 0 30px;
      border-bottom: 1px solid #494b4d;
      .mid-left {
        float: left;
        overflow: hidden;
        dl {
          margin-right: 80px;
          overflow: hidden;
          float: left;
          dt {
            margin-bottom: 25px;
            margin-left: 8px;
            color: #fff;
            font-size: 16px;
            letter-spacing: 1px;
            font-weight: 500;
          }
          dt::before {
            position: relative;
            margin-left: -8px;
            width: 4px;
            height: 16px;
            background-color: #4373f3;
            content: '';
            top: 2px;
            float: left;
          }
          dd {
            line-height: 30px;
            margin-bottom: 0.5rem;
            margin-left: 0;
            a {
              display: block;
              color: #999;
              text-decoration: none;
              font-size: 14px;
              line-height: 32px;
            }
            span {
              display: block;
              color: #999;
              text-decoration: none;
              font-size: 14px;
              line-height: 32px;
            }
          }
        }
        dl:first-child {
          dd {
            float: left;
          }
        }
        dl:nth-child(2) ~ span {
          width: 1px;
          height: 168px;
          background-color: #494b4d;
          display: block;
          float: left;
          margin: 15px 80px 0 0;
        }
        .bnc {
          display: flex;
          flex-direction: column;
          .bnc-con {
            display: flex;
            align-items: center;
            i {
              margin-right: 12px;
              color: #999;
            }
          }
          a,
          span {
            display: block;
            color: #999;
            text-decoration: none;
            font-size: 14px;
            line-height: 32px;
            margin-right: 10px;
          }
          .bnc-btn {
            a.qqchart,
            a.zx {
              width: 87px;
              display: inline-block;
              border: 1px solid #999;
              text-align: center;
              padding: 0 6px;
            }
          }
        }
      }
      .mid-right {
        float: right;
        div {
          padding: 5px;
          background-color: #fff;
          img {
            width: 64px;
            display: block;
            border: none;
          }
        }
        p {
          color: #fff;
          text-align: center;
          font-size: 13px;
          margin: 0;
          line-height: 2;
        }
      }
    }
  }
  .phonefooter {
    display: none;
    p {
      color: #555;
    }
  }
  .f-bottom {
    text-align: center;
    p {
      color: #888;
      line-height: 80px;
      margin-bottom: 0;
    }
  }
}
</style>
