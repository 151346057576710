<template>
  <div class="wrapper">
    <div :class="['header', { hidden: isHeaderHidden }]" id="header">
      <div
        class="header-bg"
        :style="headerBgStyle"
      />
      <div class="header-inder">
        <ApsHeader style="z-index: 10;position: absolute;width: 100%;" />
      </div>
    </div>
    <div id="scroller" @scroll="handleScroll" style="overflow-y: auto;">
      <div id="tiles">
        <!-- Other content remains unchanged -->
        <div class="section" style="height: 100vh;">
          <span class="background gallery-background gallery-full main-image">
            <div style="background-image: url('@/assets/1.jpg')"></div>
          </span>
          <!-- Background -->
          <span class="background gallery-background gallery-full main-image">
            <video
              :src="video5"
              autoplay
              loop
              width="100%"
              muted
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
              "
            ></video>
          </span>
          <div class="video-overlay" style="padding: 0 1%"></div>
          <div class="inner-left-bottom">
            <h2
              class="animate shadowed animate-slide-up"
              style="
                text-transform: uppercase;
                opacity: 1;
                visibility: inherit;
                translate: none;
                rotate: none;
                scale: none;
                font-size: 50px;
                transform: translate3d(0px, 0px, 0px);
              "
            >
              AI智能排产正开启AIPS新时代
              <div class="large-text">为企业带来更高的效率与精准的生产管理</div>
            </h2>
          </div>
          <!-- Scroll down button -->
          <div class="section-inner feature" style="height: 784px">
            <div
              class="scrollme"
              style="
                translate: none;
                rotate: none;
                scale: none;
                opacity: 0.0029;
                visibility: inherit;
                transform: translate3d(0px, -30.0118px, 0px);
              "
            >
              <svg width="30px" height="20px" aria-label="more content below">
                <path
                  stroke="#ffffff"
                  fill="none"
                  stroke-width="2px"
                  d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="section">
          <MainBusiness />
        </div>
        <div class="section">
          <HeroSection2 />
        </div>
        <div class="section">
          <ApsContactUs />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import video5 from '@/assets/5.mp4';
import { ref, onMounted, onUnmounted, watch } from 'vue';
import ApsHeader from './components/ApsHeader.vue';
import HeroSection2 from './components/HeroSection2.vue';
import MainBusiness from './components/MainBusiness.vue';
import ApsContactUs from './components/ApsContactUs.vue';
const isHeaderHidden = ref(false);
let lastScrollTop = ref(0);
const headerBgStyle = ref('none')
const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  isHeaderHidden.value = scrollTop > lastScrollTop.value;
  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scroll values
};

watch(()=>lastScrollTop.value, (val)=>{
  if(val < 1000){
    headerBgStyle.value = 'transform: translate3d(0, -100%, 0);'
  } else {
    headerBgStyle.value  = 'none'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style>
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  /* pointer-events: none;  */
  background-color: black;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
p {
  position: relative;
  font: 20px/38px D-DIN-Medium, Arial, Verdana, sans-serif;
  color: #ffffff;
  margin: 0px !important;
}
h1,
h2 {
  position: relative;
  font: 48px/48px D-DIN-Bold, Arial, Verdana, sans-serif;
  color: #ffffff;
  margin: auto;
}
.section {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
}
#wrapper, .section {
  position: relative;
  width: 100%;
}
.large-text {
  font-size: 20px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 70px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.header.hidden {
  opacity: 0;
  visibility: hidden;
}

.header-inder {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
}
.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  background-color: #000;
  color: white;
  z-index: 0;
  transform: translate3d(0, -100%, 0);
  /* transform: translate3d(0, -100%, 0);
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.15s,
    -webkit-transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.15s; */
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 70px;
}
.scrollme {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 30px;
  height: 20px;
  margin: 0 0 0 -15px;
  animation: pulseMove 4s infinite; /* 动画持续时间 2 秒，无限循环 */
}
.gallery-full {
  height: 100%;
  bottom: 0;
}
.gallery-background {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: auto;
  bottom: 100px;
}
.gallery-background,
.swiper-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-image {
  /* padding: 0 200px; */
  background-image: url('@/assets/1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeIn 7s forwards; /* 动画持续时间 5 秒，播放结束后保持在最后一帧 */
}
.inner-left-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* position: absolute;
  bottom: 50%;
  left: 50%; */
  /** 统一 */
  text-align: center;
  /* width: 50%; */
  /* max-width: 520px; */
}
/* 将动画应用到你的元素 */
.animate-slide-up {
  animation: slideUpFadeIn 2s ease-out; /* 动画持续时间 2 秒，缓和动画效果 */
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.3
  ); /* Added background color with opacity for better contrast */
}


/* 定义淡出动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideUpFadeIn {
  from {
    transform: translateY(20px); /* 从下面 20 像素的位置开始 */
    opacity: 0; /* 从完全透明开始 */
  }
  to {
    transform: translateY(0); /* 最终位置在原来的位置 */
    opacity: 1; /* 最终完全可见 */
  }
}

/* 定义循环动画 */
@keyframes pulseMove {
  0% {
    opacity: 0; /* 起始时透明度为 0 */
    transform: translate3d(0px, -30px, 0px); /* 起始时的位置 */
  }
  50% {
    opacity: 1; /* 中间时透明度为 1 */
    transform: translate3d(0px, -35px, 0px); /* 中间时的位置 */
  }
  100% {
    opacity: 0; /* 结束时透明度为 0 */
    transform: translate3d(0px, -40px, 0px); /* 结束时的位置 */
  }
}

</style>
