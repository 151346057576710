<template>
  <div
    class="hero-section"
    style="position: relative; margin: 0 100px; height: 100%; padding: 100px 0"
  >
    <div ref="heroImage" class="hero-image">
      <div ref="textElement" class="text-element">
        <p>通过智能对话轻松排产：我们全新的 AIPS 系统集成 AI 大模型.</p>
        <p>前端对话框让生产计划更智能、更高效。</p>
      </div>
      <div style="margin-top: 32px;">
        <ChatInput style="margin-top: 16px" />
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import ChatInput from './ChatInput.vue';

const heroImage = ref(null);
const textElement = ref(null);
let observer = null;
const handleIntersect = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('fade-in');
    } else {
      entry.target.classList.remove('fade-in');
    }
  });
};

onMounted(() => {
  observer = new IntersectionObserver(handleIntersect, {
    threshold: 0.1,
  });

  if (heroImage.value) {
    observer.observe(heroImage.value);
  }
  // if (textElement.value) {
    observer.observe(textElement.value);
  // }
  // textElement.value.forEach((textElement) => {
  //   observer.observe(textElement);
  // });
  
});

// onUnmounted(() => {
//   if (heroImage.value) {
//     observer.unobserve(heroImage.value);
//   }
// });
</script>
<style scoped>
.hero-section {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: center;
  margin: auto;
}
.text-element {
  opacity: 0; /* Start as invisible */
  transform: translateY(50px); /* Start slightly below the final position */
  transition: opacity 4s ease-out, transform 4s ease-out;
}
.text-element.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Final position */
}

.hero-image {
  position: relative;
  z-index: 10;
  height: 450px;
  width: calc(100% - 300px);
  background-color: white;
  border: #30363d solid 2px;
  margin-left: 50px;
  box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.2); /* 模糊的阴影效果 */
  border-radius: 20px; /* 可选：使图像边缘更圆滑 */
  background-color: #30363d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 32px;
  color: white;
  opacity: 0; /* Start as invisible */
  transform: translateY(20px); /* Start slightly below the final position */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.hero-image.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Final position */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: relative;
  z-index: 1;
  margin: 0 32px;
}

.text-overlay {
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.line {
  transform: translateY(0%);
  inline-size: 4px;
  inset-inline-start: calc(50% - 2px);
  block-size: 100%;
  position: relative;
  transform: translateY(-15%);
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(255, 149, 0) 100%);
}
</style>
